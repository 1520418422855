import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faBuilding } from "@fortawesome/free-solid-svg-icons"

import ErrorMessage from "../../../Elements/ErrorMessage"
import requiredFields from "../../../Summary/utils/requiredFields.json"
import { formatPrice } from "../../services/computations"

const hasIncompleteValues = (data, flow) => {
  for (let i = 0; i < requiredFields[flow].length; i++) {
    let keys = requiredFields[flow][i].split(".")
    let finalValue = keys.reduce((object, key) => object[key], data)

    if (!finalValue) return true
  }
  return false
}

const PersonalDetailsSummary = ({ data, deliveryAddress, flow }) => {
  if (hasIncompleteValues(data, flow))
    return (
      <ErrorMessage message="Some required information is missing or incomplete. Please double check if you have filled out the required fields." />
    )

  let {
    doctorAccountName,
    mobileNumber,
    emailAddress,
    courierOption,
    paymentOption,
    preferredDeliveryDate,
    preferredDeliveryTime,
    authorizedPerson,
    changeFor,
    isSameDay,
  } = data

  const addressIcon =
    deliveryAddress?.addressType === "Home" ? faHome : faBuilding
  const COD_STRING = "Cash on Delivery"
  const IS_SAME_DAY = isSameDay === "Yes"

  return (
    <div className="px-1">
      {doctorAccountName && (
        <p>
          <span className="has-text-weight-bold">{doctorAccountName}</span>
        </p>
      )}
      {mobileNumber && (
        <p>
          {mobileNumber} | {emailAddress}
        </p>
      )}
      {deliveryAddress?.streetAddress && (
        <p>
          <span className="icon is-small">
            <FontAwesomeIcon icon={addressIcon} />
          </span>{" "}
          {deliveryAddress.streetAddress}, {deliveryAddress.city.value},{" "}
          {deliveryAddress.province.value}
        </p>
      )}
      {deliveryAddress.notes && (
        <p>
          Delivery Notes:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {deliveryAddress.notes}
          </span>
        </p>
      )}
      {!IS_SAME_DAY && preferredDeliveryDate?.month?.value && (
        <p className="mt-1 mb-1-mobile">
          Preferred Delivery Date:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {preferredDeliveryDate.month.value}{" "}
            {preferredDeliveryDate.date.value},{" "}
            {preferredDeliveryDate.year?.value || preferredDeliveryDate.year}
          </span>
        </p>
      )}
      {!IS_SAME_DAY && preferredDeliveryTime && (
        <p className="mb-1-mobile">
          Preferred Delivery Time:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {preferredDeliveryTime}
          </span>
        </p>
      )}
      {paymentOption && courierOption && (
        <p className="mb-1-mobile">
          Shipping Option:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {courierOption}
            {paymentOption && courierOption && " | "}
            {paymentOption}
          </span>
        </p>
      )}
      {changeFor && paymentOption === COD_STRING && (
        <p>
          Change for:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />P
            {formatPrice({ priceString: parseFloat(changeFor).toFixed(2) })}
          </span>
        </p>
      )}
      {authorizedPerson?.fullName && (
        <p className="mt-1 mb-1-mobile">
          Authorized Person to Receive
          <div className="has-text-weight-bold">
            {authorizedPerson?.fullName}
          </div>
          <div>{authorizedPerson?.contactNumber || "N/A"}</div>
        </p>
      )}
    </div>
  )
}

export default PersonalDetailsSummary
