import { generateDeliveryFee } from "../../../Summary/services/deliveryFee"
import { accumulatePrice, formatPrice } from "../../services/computations"
import dispensingChannelBankDetails from "../dispensingChannelBankDetails.json"
import productStorageConditions from "../productStorageConditions.json"

const printDateValue = ({ date }) => {
  if (!date.month.value || !date.date.value || !date.year) return "N/A"
  return `${date.month.value} ${date.date.value} ${
    date.year?.value || date.year
  }`
}

export function orderConfirmation(request, files, zendeskID) {
  let {
    doctorAccountName,
    emailAddress,
    mobileNumber,
    deliveryAddress,
    orderNotes,
    courierOption,
    paymentOption,
    landlineNumber,
    patients,
    preferredDeliveryDate,
    preferredDeliveryTime,
    authorizedPerson,
    changeFor,
    creditCardType,
    creditCardBank,
    isSameDay,
    dispensingChannel,
    contactPerson,
    bankName,
  } = request

  const medicines = patients?.reduce(
    (allMedicines, currentPatient) =>
      allMedicines.concat(currentPatient?.medicines),
    []
  )

  let deliveryFee =
    generateDeliveryFee({
      medicines,
      dispensingChannel: courierOption,
      province: deliveryAddress?.province?.value,
      city: deliveryAddress?.city?.value,
      cartSize: accumulatePrice(medicines),
    }) || 0

  const handleViewFiles = () => {
    let fileString = ""
    files.map((file) => {
      fileString += `- ${file.filename} <br>`
      return null
    })
    return fileString
  }

  const computeOrderTotal = ({ medicines, deliveryFee }) => {
    let subtotal =
      parseFloat(accumulatePrice(medicines).toFixed(2)) + deliveryFee
    return `<b>${formatPrice({ priceString: subtotal.toFixed(2) })}</b>`
  }

  let contactPersonName =
    contactPerson.length === 0 ? authorizedPerson.fullName : doctorAccountName
  let contactPersonNumber =
    contactPerson.length === 0 ? authorizedPerson.contactNumber : mobileNumber

  const handleViewPatientOrder = () => {
    let tableItemString = ""
    patients.map((patient, index) => {
      let totalDiscountAmount = 0
      let totalDiscountString = ""
      tableItemString += `
      <table class='is-bordered'><tbody><tr><th colspan='3'>Patient: ${
        patient?.patientName
      }</th></tr>
        <tr>
          <th colspan='2'>Diagnosis</th>
          <td>${
            patient?.whyMedicineWasPrescribed?.value === "Other"
              ? patient?.otherWhyMedicineWasPrescribed
              : patient?.whyMedicineWasPrescribed?.label
          }</td>
        </tr>
        ${
          patient?.diseaseType
            ? `<tr><th colspan='2'>Subtype</th><td>${patient?.diseaseType}</td></tr>`
            : ""
        }
        ${
          patient?.lineOfTreatment
            ? `<tr><th colspan='2'>Line of Treatment</th><td>${patient?.lineOfTreatment}</td></tr>`
            : ""
        }
        ${
          patient?.therapyType
            ? `<tr><th colspan='2'>Therapy Type</th><td>${patient?.therapyType}</td></tr>`
            : ""
        }
        ${
          patient?.combinationTherapyMedicines
            ? `<tr><th colspan='2'>Combination Therapy Medicine</th><td>${patient?.combinationTherapyMedicines}</td></tr>`
            : ""
        }
        <tr>
            <th colspan='3'>Medicines Ordered</th>
        </tr>
        <tr>
            <th>SKU</th>
            <th>Quantity</th>
            <th>Subtotal</th>
        </tr>
        `
      let tempPatient = patient?.medicines?.map((medicine) => {
        tableItemString += `<tr>
            <td>${medicine?.productTitle}</td>
            <td style="text-align: right;">${medicine?.qty}</td>
            <td style="text-align: right;">P${formatPrice({
              priceString: (
                medicine?.qty *
                (
                  medicine.price_list.vatex_unit_price + medicine.price_list.vat
                ).toFixed(2)
              ).toFixed(2),
            })}</td>
          </tr>
         `

        let discountValues = medicine?.discountValues.map((discountValue) => {
          let discountType = discountValue?.type
          if (discountType === "amount")
            totalDiscountAmount += discountValue?.amount
          else if (discountType === "quantity" && discountValue?.quantity > 0)
            tableItemString += `<tr>
            <td>${discountValue?.freeProduct} - Medicine Assistance</td>
            <td style="text-align: right;">${discountValue?.quantity}</td>
            <td style="text-align: right;">P0.00</td>
        </tr> `

          return null
        })
        return null
      })
      if (totalDiscountAmount > 0)
        totalDiscountString += `<tr>
          <td colspan="2" style="text-align: right;"><b>Total discount amount:</b></td>
          <td style="text-align: right;"> (P${formatPrice({
            priceString: totalDiscountAmount.toFixed(2),
          })})</td>
      </tr> `

      if (deliveryFee > 0 && index < 1)
        totalDiscountString += `<tr>
          <td colspan="2" style="text-align: right;"><b>Delivery Fee:</b></td>
          <td style="text-align: right;"> P${formatPrice({
            priceString: deliveryFee,
          })}</td>
      </tr> `

      tableItemString += totalDiscountString
      tableItemString += `<tr>
              <td colspan="2" class="has-text-right"><b>Order Total:</b></td>
              <td class="has-text-right"><b>P${computeOrderTotal({
                medicines: patient?.medicines,
                deliveryFee: index < 1 ? deliveryFee : 0,
              })}</b></td>
            </tr>`
      tableItemString += `</tbody></table><br />`
      return null
    })
    return tableItemString
  }

  const zendeskIDTemplate = zendeskID
    ? `<h3 style="text-align: center;">ePharmacy Order Reference Number</h3><h3 style="text-align: center; background: #fadea4; padding: 1em; margin: auto; width: 20em;">${zendeskID}</h3>`
    : ""

  const IS_SAME_DAY = isSameDay === "Yes"

  let medicinesSKUList = medicines.map((medicine) => medicine.productTitle)
  let coldChainMedicines = productStorageConditions.filter(
    (productStorageCondition) =>
      productStorageCondition.storageCondition === "Cold chain" &&
      medicinesSKUList?.includes(productStorageCondition.productTitle)
  )
  let hasMultipleColdChainSKUs = coldChainMedicines?.length > 1
  let roomTemperatureMedicines = productStorageConditions.filter(
    (productStorageCondition) =>
      productStorageCondition.storageCondition === "Room temperature" &&
      medicinesSKUList?.includes(productStorageCondition.productTitle)
  )
  let hasMultipleRoomTempSKUs = roomTemperatureMedicines?.length > 1

  return /*html*/ `
    <!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">

<head>
    <style>
        body {
            font-family: 'Lato', sans-serif;
        }

        .container {
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          flex-direction: row;
          padding: 25px;
          width: 100%;
          justify-content: center;
        }
      
        @media only screen and (min-width: 770px) {
          .content {
            width: 60%;
          }
        }

        table {
            border-collapse: collapse;
            width: 100%;
        }

        table,
        th,
        td {
            border: 1px solid #ccc;
        }

        td {
            padding: 10px;
        }

        th {
            text-align: left;
            padding: 10px;
        }

        .is-bordered {
            border: 1px solid #ccc;
        }

        h3 {
          margin-top: .75rem;
        }

        .terms {
          padding: 10px;
          background: #EEEEEE;
          font-size: 11px;
          color: #606060
        }

        .has-text-right {
          text-align: right
        }

        .terms th, .terms td {
          padding: 5px;
        }

        .personal-information td {
          width: 30%;
        }
    </style>
</head>

<body>
<div class="container">
  <div class="content">
    <p>Hi ${doctorAccountName},</p>
    <p>Thank you for ordering from CarePlus.</p>
    ${zendeskIDTemplate}
    <br />
    ${
      coldChainMedicines?.length > 0
        ? `<p>For your order${
            !!hasMultipleColdChainSKUs ? "s" : ""
          } of ${coldChainMedicines
            ?.map((coldChainMedicine) => coldChainMedicine.productTitle)
            ?.join(", ")}, please store ${
            !!hasMultipleColdChainSKUs ? "them" : "it"
          } in a refrigerator (${
            coldChainMedicines?.[0]?.temperature
          }) upon receipt, keep ${
            !!hasMultipleColdChainSKUs ? "them " : ""
          }in ${!!hasMultipleColdChainSKUs ? "their" : "its"} original box${
            !!hasMultipleColdChainSKUs ? "es" : ""
          } until time of use, protect from light, do not freeze, and do not shake. Please note that ${
            !!hasMultipleColdChainSKUs ? "these" : "this"
          } medicine${
            !!hasMultipleColdChainSKUs ? "s" : ""
          } cannot be accommodated under our return policy.</p>`
        : ""
    }
    ${
      roomTemperatureMedicines?.length > 0
        ? `<p>For your order${
            !!hasMultipleRoomTempSKUs ? "s" : ""
          } of ${roomTemperatureMedicines
            ?.map((coldChainMedicine) => coldChainMedicine.productTitle)
            ?.join(", ")}, please store ${
            !!hasMultipleRoomTempSKUs ? "them" : "it"
          } in room temperature (${
            roomTemperatureMedicines?.[0]?.temperature
          }), protect from sunlight, and keep out of sight and reach of children.</p>`
        : ""
    }
    <p>Our pharmacist will reach out to you during business hours (Mon - Fri, 8:30AM-4:30PM) for any further updates. If you do not receive an SMS, contact us with your reference number ${zendeskID} at 0917 866 9487.</p>
    
    <p><b>Please note that the total order amount and medicine assistance indicated below is not final until verified by our pharmacist via SMS.</b></p>

    <p>
        <h2>Review Your Responses</h2>
        Please review your responses to make sure all details are correct.
    </p>
    <h3>Personal Information</h3>
    <table class='is-bordered personal-information'>
        <tbody>
            <tr>
                <th>Name</th>
                <td>${doctorAccountName}</td>
            </tr>
            <tr>
                <th>Mobile Number</th>
                <td>${mobileNumber}</td>
            </tr>
            <tr>
                <th>Landline</th>
                <td>${landlineNumber || "N/A"}</td>
            </tr>
            <tr>
                <th>Email</th>
                <td>${emailAddress}</td>
            </tr>
            <tr>
                <th>Delivery Address (${deliveryAddress.addressType})</th>
                <td>${deliveryAddress.streetAddress}, ${
    deliveryAddress.city.value
  }, ${deliveryAddress.province.value}${
    deliveryAddress?.notes ? ` (${deliveryAddress.notes})` : ""
  }</td>
            </tr>
            ${
              IS_SAME_DAY
                ? ""
                : `<tr>
            <th>Preferred Delivery Date</th>
              <td>${printDateValue({ date: preferredDeliveryDate })}</td>
            </tr>
            <tr>
              <th>Preferred Delivery Time</th>
              <td>${preferredDeliveryTime}</td>
            </tr>`
            }
            <tr>
              <th>Authorized Person to Receive</th>
              <td>${contactPersonName}</td>
            </tr>
            <tr>
                <th>Contact Number</th>
                <td>${contactPersonNumber}</td>
            </tr>
            <tr>
                <th> If your preferred dispensing channel encounters challenges delivering your order</th>
                <td>${dispensingChannel || "N/A"}</td>
            </tr>
            <tr>
                <th>Order Notes</th>
                <td>${orderNotes || "N/A"}</td>
            </tr>
            <tr>
                <th>Payment Method</th>
                <td>${
                  paymentOption === "Bank Transfer/Deposit"
                    ? `Bank Transfer/Deposit - ${bankName}`
                    : paymentOption || "N/A"
                }</td>
            </tr>
            ${
              paymentOption === "Cash on Delivery"
                ? changeFor
                  ? `<tr>
                <th>Change For</th>
                <td>P${formatPrice({
                  priceString: parseFloat(changeFor).toFixed(2),
                })}</td>
            </tr>`
                  : ""
                : ""
            }
            ${
              paymentOption === "Credit Card on Delivery" &&
              creditCardBank &&
              creditCardType
                ? ` <tr>
                    <th>Issuing Bank</th>
                      <td>
                       ${creditCardBank}
                      </td>
                    </tr>
                    <tr>
                    <th>Credit Card Type</th>
                      <td>
                        ${creditCardType}
                      </td>
                    </tr>`
                : ""
            }
            <tr>
                <th>Preferred Dispensing Channel</th>
                <td>${courierOption}</td>
            </tr>
        </tbody>
    </table>
    <br />
    <h3>Medicines Ordered</h3>    
    ${handleViewPatientOrder()}

    <br>
        ${
          files.length > 0
            ? `
          <h3>Files Uploaded (${files.length})</h3>
              ${handleViewFiles()}
          </table>
        `
            : ""
        }
    
    ${
      courierOption !==
      "(Recommended) Best dispensing channel with available stocks and can deliver on your preferred time"
        ? `<h3>Bank Details</h3>`
        : ""
    }
    ${dispensingChannelBankDetails
      ?.map(
        (dispensingChannel) =>
          `<table class="is-bordered">
          <tr>
            <th colspan="2">${dispensingChannel?.label}</th>
          </tr>
          <tbody>
            ${dispensingChannel?.banks
              ?.map(
                (bank) => `<tr>
              <td width="25%"><b>${bank?.name}</b></td>
              <td width="75%">
                ${bank?.details
                  ?.split("\n")
                  ?.map(
                    (detail, index) =>
                      `${index > 0 ? "<br />" : ""}<span>${detail}</span>`
                  )
                  .join("")}
              </td>
            </tr>`
              )
              .join("")}
          </tbody>
        </table>`
      )
      .join("")}
<br />
    <p>This is an automated email. For any revisions, please reply CANCEL ORDER to the verification SMS our pharmacist will send you and place your corrected order on careplus.medgrocer.com. This is to prevent miscommunication and minimize inaccuracies.</p>

    <p>Thank you. We hope to serve you again in the future.</p>

    <p>Regards, <br /> The CarePlus Team</p>
    
    <b>Data Privacy</b>
      <ul>
        <li>The above information is correct and I give Johnson & Johnson Philippines Inc. (“J&J Philippines”) and MedGrocer the authority to collect, store, and use my information for the CarePlus Program (“Program”) and its related administrative activities. Subject to certain exceptions allowed by the law, I may revise my personal information and inform MedGrocer to update and store the same. MedGrocer may store my personal information for a period of six (6) years from the end of the program without any further consent needed from my end.</li>
        <li>J&J Philippines and MedGrocer acknowledge and respect the privacy of individuals.</li>
        <li>J&J Philippines has engaged MedGrocer to collect and hold my information in order to organize my participation in the Program and to communicate with me regarding the Program. By completing this form and providing the information requested, I consent to J&J Philippines, MedGrocer, and their partners using that information for the purposes outlined.  J&J Philippines and MedGrocer may access and disclose the information to respond to regulatory authorities or if required by law.</li>
        <li>I am not obliged to provide the information and I may choose not to. If I do not provide this information, J&J Philippines and MedGrocer may not be able to provide the needed support to me under the Program or administer the Program effectively or at all.</li>
        <li>If I would like to access, update, or correct my information or if I do not want my information retained on the database, I should contact MedGrocer.</li>
      </ul>

    <b>Program End</b>
      <ul>
        <li>I understand that J&J Philippines and MedGrocer have the right to terminate the Program prior to the end date and their representative may retrieve all the remaining communication materials.</li>
      </ul>

    <b>Waiver</b>
      <ul>
        <li>I will indemnify and hold J&J Philippines, MedGrocer, and their partners free from any liabilities that may arise as a consequence of my decision to join the Program. I also acknowledge that J&J Philippines or MedGrocer shall not be responsible nor liable for any loss or damage incurred or suffered as a consequence of: A) any delay or inability of MedGrocer to perform any of its obligations due to any mechanical, data processing, telecommunication failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery; and B) any damage to or loss of or inability to retrieve any data that may be stored in the MedGrocer database.</li>
      </ul>
    </div>
  </div>
</div>
</body>

</html>
  `
}
