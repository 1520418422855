import React, { Fragment } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTags } from "@fortawesome/free-solid-svg-icons"

import {
  generatePrice,
  formatPrice,
} from "../../Epharmacy/services/computations"
import { generateTotalMedsToPay } from "../../Epharmacy/services/cart"

const MedicineInfoRow = ({ medicine }) => {
  const discountValues = medicine?.discountValues || []
  const totalMedsToPay = generateTotalMedsToPay({
    medicine,
    quantity: medicine?.qty,
  })
  const totalItemPrice = formatPrice({
    priceString: (generatePrice(medicine) * totalMedsToPay).toFixed(2),
  })

  return (
    <div className={classNames("mt-1 pb-1 has-border-bottom")}>
      <div className="has-text-primary has-text-weight-bold">
        {medicine.productTitle}
      </div>
      <div className="columns mb-1 is-mobile">
        <div className="column pb-0">Qty: {totalMedsToPay}</div>
        <div className={classNames("column pb-0 has-text-right")}>
          P{totalItemPrice}
        </div>
      </div>
      {discountValues.map((discountValue) => {
        const discountAmount = formatPrice({
          priceString: discountValue?.amount?.toFixed(2),
        })
        const discountFreeProduct = discountValue?.freeProduct
        const discountDescription =
          discountValue?.type === "amount" ? (
            <span className="has-text-weight-bold">
              {discountValue?.code?.startsWith("SCPWD")
                ? "SCPWD Discount"
                : `${
                    parseFloat(discountValue?.percentOff) % 1 === 0
                      ? parseFloat(discountValue?.percentOff).toFixed(0)
                      : parseFloat(discountValue?.percentOff).toFixed(2)
                  }% Discount`}
            </span>
          ) : (
            <span>Qty: {discountValue?.quantity}</span>
          )
        const discountValueAmount =
          discountValue?.type === "amount" ? `(P${discountAmount})` : "P0.00"

        if (discountValue?.amount || discountValue?.quantity)
          return (
            <Fragment>
              {discountValue?.freeProduct && (
                <div className="has-text-weight-bold">
                  {discountFreeProduct}
                </div>
              )}
              {discountValue?.type && (
                <div className="columns is-mobile">
                  <div
                    className={`column is-${
                      discountValue?.quantity ? "9" : "7"
                    }`}
                  >
                    {discountDescription}
                  </div>
                  <div className={classNames("column has-text-right")}>
                    {discountValueAmount}
                  </div>
                </div>
              )}
            </Fragment>
          )
        return null
      })}
    </div>
  )
}

export const DeliveryFeeRow = ({ deliveryFee }) => (
  <div className={classNames("mt-1")}>
    <div className="columns is-mobile">
      <div className="column has-text-weight-bold">Delivery Fee</div>
      <div className={classNames("column has-text-right")}>P{deliveryFee}</div>
    </div>
  </div>
)

export const CouponDiscountRow = ({ couponCode, couponDiscount }) => (
  <div className={classNames("pb-1 has-border-bottom")}>
    <div className="columns is-mobile">
      <div className="column has-text-weight-bold">
        <span className="icon has-text-primary">
          <FontAwesomeIcon icon={faTags} />
        </span>{" "}
        Discount ({couponCode})
      </div>
      <div className={classNames("column has-text-right has-text-danger")}>
        - P{couponDiscount}
      </div>
    </div>
  </div>
)

export default MedicineInfoRow
