import React from "react"
import {
  formatPrice,
  accumulatePrice,
  generateDeliveryFee,
} from "../../services/computations"

const OrderDetailsSummaryRow = ({ patient }) => {
  const orderSubtotal = formatPrice({
    priceString: (
      accumulatePrice(patient?.medicines) + generateDeliveryFee()
    ).toFixed(2),
  })

  return (
    <tr>
      <td>{patient?.patientName}</td>
      <td className="has-text-right">P{orderSubtotal}</td>
    </tr>
  )
}

export default OrderDetailsSummaryRow
