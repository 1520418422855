import React, { Fragment } from "react"
import { Link } from "gatsby"

import Table from "../../../Elements/Table"
import Message from "../../../Elements/Message"
import ErrorMessage from "../../../Elements/ErrorMessage"
import OrderSubtotalSummary from "./OrderSubtotalSummary"
import OrderDetailsSummaryRow from "./OrderDetailsSummaryRow"

import { deliveryChannelUpdates } from "./utils/deliveryChannelUpdates"
import { DeliveryFeeRow } from "../../../Summary/MedsOrderedSummary/MedicineInfoRow"

const OrderDetailsSummary = ({ patients, courier, deliveryFee }) => {
  const channelUpdates = deliveryChannelUpdates.find(
    (channel) => channel.courierOption === courier
  )

  if (!patients?.length)
    return (
      <ErrorMessage
        message={
          <div className="has-text-centered">
            <p className="is-size-6">Your patient list is empty.</p>
            <Link to="/special-order">Add Patients</Link>
          </div>
        }
      />
    )
  return (
    <Fragment>
      <Table
        headers={[
          { data: "Patient" },
          { data: "Order Subtotal", className: "has-text-right" },
        ]}
      >
        {patients?.map((patient) => (
          <OrderDetailsSummaryRow patient={patient} />
        ))}
      </Table>
      {deliveryFee > 0 && (
        <div className="mx-1">
          <DeliveryFeeRow deliveryFee={deliveryFee.toFixed(2)} />
        </div>
      )}
      {channelUpdates && (
        <Message color="warning" className="mt-1">
          <p className="has-text-black is-size-6">{channelUpdates.message}</p>
        </Message>
      )}
      <OrderSubtotalSummary deliveryFee={deliveryFee} />
    </Fragment>
  )
}

export default OrderDetailsSummary
