import { accumulatePrice, generatePrice } from "../../services/computations"
import { getLabelFromDiscountTier } from "../../services/customerTypes"
import { generateDeliveryFee } from "../../../Summary/services/deliveryFee"
import { getSKUSubstitution } from "../../services/getSKUSubstitution"

const printDateValue = ({ date }) => {
  if (!date.month.value || !date.date.value || !date.year) return "N/A"
  return `${date.month.value} ${date.date.value} ${
    date.year?.value || date.year
  }`
}

export function orderTicketBody(request) {
  let {
    doctorAccountName,
    middleInitial,
    emailAddress,
    mobileNumber,
    deliveryAddress,
    paymentOption,
    changeFor,
    creditCardBank,
    creditCardType,
    orderNotes,
    courierOption,
    landlineNumber,
    patients,
    preferredDeliveryDate,
    preferredDeliveryTime,
    authorizedPerson,
    isSameDay,
    otherDispensingChannel,
    dispensingChannel,
    contactPerson,
    bankName,
    products,
    agreeContactPerson,
  } = request

  let medicinesArray = ""

  const medicines = patients?.reduce(
    (allMedicines, currentPatient) =>
      allMedicines.concat(currentPatient?.medicines),
    []
  )

  let deliveryFee =
    generateDeliveryFee({
      medicines,
      dispensingChannel: courierOption,
      province: deliveryAddress?.province?.value,
      city: deliveryAddress?.city?.value,
      cartSize: accumulatePrice(medicines),
    }) || 0

  let newProductTitles = getSKUSubstitution({
    type: "Special",
  })

  let patientsList = patients?.map((patient) => {
    let medicinesList = patient?.medicines?.map((medicine) => {
      let discountType =
        medicine?.discountType === "Buy 6, Get 2"
          ? "6+2"
          : medicine?.discountType

      if (
        newProductTitles?.[medicine?.customerType]?.[medicine.productTitle]
          ?.length > 1
      ) {
        newProductTitles[medicine?.customerType][medicine.productTitle].map(
          (newProductTitle) => {
            medicinesArray += `${
              medicine?.customerType ||
              getLabelFromDiscountTier({
                medicine,
                discountTier: medicine?.discountTier,
              }) ||
              discountType ||
              "N/A"
            }, ${patient?.patientName.replace(
              /,/g,
              " "
            )}, ${newProductTitle}, #${medicine?.qty}, ${generatePrice(
              { ...medicine, productTitle: newProductTitle },
              products
            )}\n`

            return null
          }
        )
      } else {
        medicinesArray += `${
          medicine?.customerType ||
          getLabelFromDiscountTier({
            medicine,
            discountTier: medicine?.discountTier,
          }) ||
          discountType ||
          "N/A"
        }, ${patient?.patientName.replace(/,/g, " ")}, ${
          newProductTitles?.[medicine?.customerType]?.[medicine.productTitle] ||
          medicine.productTitle
        }, #${medicine?.qty}, ${generatePrice(medicine, products)}\n`
      }

      let discountsList = medicine?.discountValues?.map((discountValue) => {
        let discountType =
          medicine?.discountType === "Buy 6, Get 2"
            ? "6+2"
            : medicine?.discountType
        let finalProductTitle = ""

        if (discountValue?.type)
          if (discountValue?.freeProduct && discountValue?.quantity >= 0) {
            finalProductTitle = `${discountValue?.freeProduct} - Medicine Assistance`
            medicinesArray += `${
              medicine?.customerType ||
              getLabelFromDiscountTier({
                medicine,
                discountTier: medicine?.discountTier,
              }) ||
              discountType ||
              "N/A"
            }, ${patient?.patientName.replace(/,/g, " ")}, ${
              newProductTitles?.[medicine?.customerType]?.[finalProductTitle] ||
              finalProductTitle
            }, #${discountValue?.quantity}, 0.00\n`
          } else {
            finalProductTitle = `${medicine?.productTitle} - ${
              discountValue?.code?.startsWith("SCPWD")
                ? "SCPWD Discount"
                : `${
                    parseFloat(discountValue?.percentOff) % 1 === 0
                      ? parseFloat(discountValue?.percentOff).toFixed(0)
                      : parseFloat(discountValue?.percentOff).toFixed(2)
                  }% Discount`
            }`

            if (
              newProductTitles?.[medicine?.customerType]?.[finalProductTitle]
                ?.length > 1
            ) {
              newProductTitles[medicine?.customerType][finalProductTitle].map(
                (newProductTitle) => {
                  medicinesArray += `${
                    medicine?.customerType ||
                    getLabelFromDiscountTier({
                      medicine,
                      discountTier: medicine?.discountTier,
                    }) ||
                    discountType ||
                    "N/A"
                  }, ${patient?.patientName.replace(
                    /,/g,
                    " "
                  )}, ${newProductTitle}, #${medicine?.qty}, -${(
                    parseFloat(discountValue?.percentOff / 100) *
                    generatePrice(
                      {
                        ...medicine,
                        productTitle: `${newProductTitle?.split(")")?.[0]})`,
                      },
                      products
                    )
                  ).toFixed(2)}\n`

                  return null
                }
              )
            } else {
              medicinesArray += `${
                medicine?.customerType ||
                getLabelFromDiscountTier({
                  medicine,
                  discountTier: medicine?.discountTier,
                }) ||
                discountType ||
                "N/A"
              }, ${patient?.patientName.replace(/,/g, " ")}, ${
                newProductTitles?.[medicine?.customerType]?.[
                  finalProductTitle
                ] || finalProductTitle
              }, #${medicine?.qty}, -${(
                parseFloat(discountValue?.amount) / medicine?.qty
              ).toFixed(2)}\n`
            }
          }

        return null
      })
      return null
    })
    return null
  })

  const IS_SAME_DAY = isSameDay === "Yes"

  let contactPersonName =
    contactPerson.length === 0 ? authorizedPerson.fullName : doctorAccountName
  let contactPersonNumber =
    contactPerson.length === 0 ? authorizedPerson.contactNumber : mobileNumber

  return `Doctor/Account name: ${doctorAccountName.replace(/,/g, " ")}
  Middle initial: ${middleInitial || "N/A"}
  Mobile number: ${mobileNumber}
  Landline: ${landlineNumber || "N/A"} 
  Email: ${emailAddress}
  Street address: ${deliveryAddress.streetAddress} 
  City/Municipality: ${deliveryAddress.city.value}
  Province: ${deliveryAddress.province.value}  
  Barangay: ${deliveryAddress?.barangay?.value}  
  Address type: ${deliveryAddress.addressType}
  ZIP Code: ${deliveryAddress?.zipCode?.value || "N/A"} 
  ✅ ${agreeContactPerson?.join()} 
  Preferred Delivery Date: ${
    IS_SAME_DAY
      ? "Same day delivery"
      : printDateValue({ date: preferredDeliveryDate })
  } 
  Preferred Delivery Time: ${
    IS_SAME_DAY ? "Same day delivery" : preferredDeliveryTime
  } 
  Authorized Person to receive: ${contactPersonName} 
  Contact number of authorized person: ${contactPersonNumber} 
  Delivery notes: ${deliveryAddress?.notes || "N/A"}
  
  Medicines ordered:
  ${medicinesArray}

  Order notes: ${orderNotes || "N/A"}
  If your preferred dispensing channel encounters challenges delivering your order: ${
    dispensingChannel || ""
  }
  Other dispensing channel: ${
    dispensingChannel === "(Recommended) Switch to another dispensing channel"
      ? otherDispensingChannel
      : "N/A"
  }
  Preferred courier: ${courierOption} 
  Payment method: ${
    paymentOption === "Bank Transfer/Deposit"
      ? `Bank Transfer/Deposit - ${bankName}`
      : paymentOption || "N/A"
  }
  Bank Name: ${bankName || ""}
  Change For: ${paymentOption === "Cash on Delivery" ? changeFor : "N/A"}
  Delivery Fee: ${deliveryFee || 0}
  Credit Card Bank: ${
    paymentOption === "Credit Card Upon Delivery" && creditCardBank
      ? creditCardBank
      : "N/A"
  }
  Credit Card Type: ${
    paymentOption === "Credit Card Upon Delivery" && creditCardType
      ? creditCardType
      : "N/A"
  }
  `
}
