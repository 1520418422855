import React from "react"
import classNames from "classnames"

import styles from "../utils/summary.module.scss"

const FileFigure = ({ children, isPdf, filename }) => (
  <div className="columns is-vcentered">
    <div className={`column ${isPdf && "pl-0"}`}>
      <figure
        className={classNames("is-flex", styles["column__figureAlignItems"])}
      >
        {children}
        <span className={classNames(`is-size-5 ${!isPdf && "pl-1"}`)}>
          {filename}
        </span>
      </figure>
    </div>
  </div>
)

export default FileFigure
