import React from "react"
import classNames from "classnames"

const Table = ({ headers, children, className }) => (
  <table className={classNames("table is-fullwidth", className || "")}>
    <thead>
      <tr>
        {headers.map((header) => (
          <th className={header?.className || ""}>{header?.data || ""}</th>
        ))}
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
)

export default Table
