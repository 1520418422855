import dispensingChannelDeliveryFee from "../utils/dispensingChannelDeliveryFee.json"

export const generateDeliveryFee = (config) => {
  let { dispensingChannel, medicines, province, cartSize, city } = config
  let finalDeliveryFee = 0

  let dispensingChannelDeliveryFees = dispensingChannelDeliveryFee.filter(
    (deliveryFee) => deliveryFee.name === dispensingChannel
  )

  if (dispensingChannelDeliveryFees?.length < 1) return finalDeliveryFee
  else {
    for (let index in dispensingChannelDeliveryFees) {
      let deliveryFee = dispensingChannelDeliveryFees[index]
      let excludedProvinces = deliveryFee?.exclusions?.province || []
      let excludedCities = deliveryFee?.exclusions?.cities?.[province] || []
      let includedMedicines = deliveryFee?.inclusions?.medicines || []
      let maxCartSize = deliveryFee?.maxCartSize || 0
      let cartHasIncludedMedicines = medicines.some((medicine) =>
        includedMedicines.includes(medicine?.productTitle)
      )

      finalDeliveryFee = deliveryFee.amount

      if (excludedProvinces.includes(province)) {
        if (excludedCities?.length > 0) {
          if (excludedCities?.includes(city)) finalDeliveryFee = 0
        } else finalDeliveryFee = 0
      }
      if (maxCartSize > 0 && cartSize > maxCartSize) finalDeliveryFee = 0
      if (includedMedicines.length > 0 && !cartHasIncludedMedicines)
        finalDeliveryFee = 0

      if (finalDeliveryFee > 0) return finalDeliveryFee
    }
  }
}
