import api from "./api"

// const API_URL = process.env.GATSBY_TELERIVET_API_URL
// const API_KEY = process.env.GATSBY_TELERIVET_API_KEY
// const PROJECT_ID = process.env.GATSBY_TELERIVET_PROJECT_ID

export const sendMessage = async ({ message, recipient, callback }) => {
  const response = await api
    .post("telerivet/send-message/", {
      message_content: message,
      contact_number: recipient,
    })
    .then(() => {
      if (callback) callback()
    })

  return response
}
