import React, { Fragment, useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"

import Section from "../../Elements/Section"
import ActionButtons from "../../Elements/ActionButtons"
import EditDetailsButton from "../../Summary/EditDetailsButton"
import ConsentAndAuthorization from "../../Summary/ConsentAndAuthorization"
import OrderDetailsSummary from "./SpecialOrderSummary/OrderDetailsSummary"
import UploadDocumentsSummary from "./SpecialOrderSummary/UploadedDocumentsSummary"
import PersonalDetailsSummary from "./SpecialOrderSummary/PersonalDetailsSummary"

import { sendRequest } from "../../../services/order"
import { shouldBeDisabled } from "../services/summary"
import { AppContext } from "../../../context/AppContext"
import { accumulatePrice } from "../services/computations"
import { orderTicketBody } from "../utils/templates/specialOrderZendeskTemplate"
import { orderConfirmation } from "../utils/templates/specialOrderEmailTemplate"
import termsAndConditionsData from "../../Enrollment/utils/doctorConsentAndAuthorization.json"
import { specialOrderTextMessageTemplate } from "../utils/templates/orderTextMessageTemplate"
import { generateDeliveryFee } from "../../Summary/services/deliveryFee"
import { useEpharmacyProducts } from "../hooks/useEpharmacyProducts"

const SpecialOrderSummary = ({ route }) => {
  const products = useEpharmacyProducts()
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy, proofOfPayment } = state
  const { deliveryAddress, patients } = epharmacy
  let uploadedDocuments = patients.reduce(
    (allFiles, currentPatient) => allFiles.concat(currentPatient?.documents),
    []
  )
  if (proofOfPayment?.path) uploadedDocuments.push(proofOfPayment)
  const medicines = patients?.reduce(
    (allMedicines, currentPatient) =>
      allMedicines.concat(currentPatient?.medicines),
    []
  )

  let deliveryFee =
    generateDeliveryFee({
      medicines,
      dispensingChannel: epharmacy.courierOption,
      province: deliveryAddress?.province?.value,
      city: deliveryAddress?.city?.value,
      cartSize: accumulatePrice(medicines),
    }) || 0

  const handleOnSubmit = async () => {
    setLoading(true)
    await sendRequest({
      flow: "epharmacy",
      details: state,
      fileArray: uploadedDocuments,
      templateConfirmation: orderConfirmation,
      templateZendesk: orderTicketBody,
      templateTextMessage: specialOrderTextMessageTemplate,
      callback: async () => {
        await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
        await dispatch({ type: "RESET_DETAILS" })
        navigate("/special-order/completed")
      },
      route,
      products,
    })
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Section
        title="Personal Details"
        addOns={{
          right: <EditDetailsButton route="/special-order/delivery-details" />,
        }}
      >
        <PersonalDetailsSummary
          data={epharmacy}
          deliveryAddress={deliveryAddress}
          flow="specialOrder"
        />
      </Section>
      <Section
        title="Documents Uploaded"
        addOns={{
          right: <EditDetailsButton route="/special-order" />,
        }}
      >
        <UploadDocumentsSummary />
      </Section>
      <Section
        title="Order Details"
        addOns={{
          right: <EditDetailsButton route="/special-order" />,
        }}
      >
        <OrderDetailsSummary
          patients={patients}
          courier={epharmacy.courierOption}
          deliveryFee={deliveryFee}
        />
      </Section>
      <ConsentAndAuthorization
        termsAndConditionsData={termsAndConditionsData}
        handleOnSubmit={handleOnSubmit}
        flow="epharmacy"
      >
        <div className="notification is-warning has-text-centered is-size-6 mt-3">
          Order is not final until verified by our pharmacist via SMS.
        </div>
        <ActionButtons
          submit={{
            label: "Place Order",
            loading: loading,
            disabled: shouldBeDisabled({
              formData: epharmacy,
              medicines: patients,
            }),
          }}
          back={{ label: "Back", link: "/special-order/shipping-and-payment" }}
        />
      </ConsentAndAuthorization>
    </Fragment>
  )
}

export default SpecialOrderSummary
