import discountTiers from "../utils/CustomerTypes/discountTiers.json"
import discountTierOptions from "../utils/CustomerTypes/discountTierOptions.json"
import discountTierLabels from "../utils/CustomerTypes/discountTierLabels.json"
import customCustomerTypeLabels from "../utils/CustomerTypes/customCustomerTypeLabels.json"
import customCustomerTypeOptions from "../utils/CustomerTypes/customCustomerTypeOptions.json"
import defaultCustomerTypeLabels from "../utils/CustomerTypes/defaultCustomerTypeLabels.json"
import defaultCustomerTypeOptions from "../utils/CustomerTypes/defaultCustomerTypeOptions.json"

export const getLabelFromDiscountTier = ({ medicine, discountTier }) => {
  return (
    discountTierOptions.find(
      (discount) =>
        discount?.medicine === medicine?.productTitle &&
        discount?.tier === discountTier
    )?.label || "N/A"
  )
}

export const getCustomerTypeOptions = ({ medicine }) => {
  let filteredCustomerTypeOptions = customCustomerTypeOptions
    .filter(
      (customerTypeOption) =>
        customerTypeOption?.medicine === medicine?.productTitle
    )
    ?.map((customerTypeOption) => customerTypeOption?.label)

  if (filteredCustomerTypeOptions?.length > 0)
    return filteredCustomerTypeOptions
  return defaultCustomerTypeOptions
}

export const getCustomerTypeLabel = ({ medicine, isSpecialOrder }) => {
  let filteredCustomerTypeLabels = customCustomerTypeLabels
    .filter(
      (customerTypeLabel) =>
        customerTypeLabel?.medicine === medicine?.productTitle
    )
    ?.map((customerTypeLabel) =>
      isSpecialOrder
        ? customerTypeLabel?.specialOrderLabel
        : customerTypeLabel?.regularOrderLabel
    )

  if (filteredCustomerTypeLabels?.length > 0)
    return filteredCustomerTypeLabels[0]
  if (isSpecialOrder) return defaultCustomerTypeLabels?.specialOrderLabel
  return defaultCustomerTypeLabels?.regularOrderLabel
}

export const isFirstTimeCustomerType = ({ customerType }) => {
  const FIRST_TIME_CUSTOMER_TYPES = [
    "First-time customer",
    "Initiation dose",
    "1st to 6th cycle",
  ]

  let filteredCustomerTypes = FIRST_TIME_CUSTOMER_TYPES?.filter(
    (firstTimeCustomerType) => firstTimeCustomerType === customerType
  )

  return filteredCustomerTypes?.length > 0
}

export const getDiscountTierLabel = ({ medicine, isSpecialOrder }) => {
  let filteredDiscountTierLabels = discountTierLabels
    .filter(
      (discountTierLabel) =>
        discountTierLabel?.medicine === medicine?.productTitle
    )
    ?.map((discountTierLabel) =>
      isSpecialOrder
        ? discountTierLabel?.specialOrderLabel
        : discountTierLabel?.regularOrderLabel
    )

  if (filteredDiscountTierLabels?.length > 0)
    return filteredDiscountTierLabels[0]
  if (isSpecialOrder) return defaultCustomerTypeLabels?.specialOrderLabel
  return defaultCustomerTypeLabels?.regularOrderLabel
}

export const getDiscountTierOptions = ({ medicine }) => {
  const filteredDiscountTierOptions = discountTierOptions
    ?.filter(
      (discountTier) => discountTier?.medicine === medicine?.productTitle
    )
    ?.map((discountTier) => discountTier?.label)

  return filteredDiscountTierOptions
}

export const getDiscountTier = ({ medicine, selectedOption }) => {
  const filteredDiscountTier = discountTierOptions?.find(
    (discountTier) =>
      discountTier?.medicine === medicine?.productTitle &&
      discountTier?.label === selectedOption
  )

  return filteredDiscountTier.tier
}

export const hasTierDiscount = ({ medicine, discountCoupons }) => {
  let discountTiersObject = discountTiers.find((item) => {
    return item.medicine === medicine?.productTitle
  })

  if (discountTiersObject) {
    const discountTier = discountTiersObject?.tiers[0]
    const hasTierDiscountInCoupon = discountCoupons.filter((discountCoupon) => {
      let containsProductInCoupon = discountCoupon?.productInclusions?.filter(
        (product) => product?.title === medicine?.productTitle
      ).length

      return (
        containsProductInCoupon && discountCoupon?.code?.includes(discountTier)
      )
    }).length

    return hasTierDiscountInCoupon
  }

  return false
}
