import moment from "moment"

export const orderTextMessageTemplate = ({ zendeskID }) => {
  return `[${moment().format(
    "YYYY/MM/DD hh:mm:ss A"
  )}] Your order is being processed. Our pharmacist will reach out to you during business hours (Mon - Fri, 8:30AM-4:30PM) for any further updates. If you do not receive an SMS, contact us with your reference number ${zendeskID} at 0917 866 9487. Thank you.`
}

export const specialOrderTextMessageTemplate = ({
  zendeskID,
}) => {
  return `[${moment().format(
    "YYYY/MM/DD hh:mm:ss A"
  )}] Your order is being processed. Our pharmacist will reach out to you during business hours (Mon - Fri, 8:30AM-4:30PM) for any further updates. If you do not receive an SMS, contact us with your reference number ${zendeskID} at 0917 866 9487.\n\nThank you.`
}