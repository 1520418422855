import React, { useContext, Fragment } from "react"
import { Link } from "gatsby"

import ErrorMessage from "../../../Elements/ErrorMessage"
import FileThumbnail from "../../../Summary/UploadedDocumentsSummary/FileThumbnail"

import { AppContext } from "../../../../context/AppContext"

const UploadDocumentsSummary = () => {
  const { state } = useContext(AppContext)
  const { epharmacy, proofOfPayment } = state
  const { patients } = epharmacy
  const files = patients.reduce(
    (allFiles, currentPatient) => allFiles.concat(currentPatient?.documents),
    []
  )
  if (proofOfPayment?.path) files.push(proofOfPayment)

  if (files.length === 0)
    return (
      <ErrorMessage
        message={
          <Fragment>
            <p className="is-size-6">You have not uploaded any documents.</p>
            <Link to="/special-order">Upload Documents</Link>
          </Fragment>
        }
      />
    )
  return (
    <div className="px-1 mx-1">
      <div className="columns is-vcentered is-multiline">
        {files.map((file) => (
          <div className="column is-6">
            <FileThumbnail file={file} hasFileName />
          </div>
        ))}
      </div>
    </div>
  )
}

export default UploadDocumentsSummary
