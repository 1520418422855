import skuSubstitution from "../utils/skuSubstitution.json"

export const getSKUSubstitution = ({ type }) => {
  return skuSubstitution
    .filter((skuConfig) => skuConfig.type === type)
    .reduce((skuSubstitutionObject, skuConfig) => {
      skuSubstitutionObject[skuConfig.classification] = {
        ...(skuSubstitutionObject?.[skuConfig.classification] || {}),
        [skuConfig.oldProductTitle]: [
          ...(skuSubstitutionObject?.[skuConfig.classification]?.[
            skuConfig.oldProductTitle
          ] || []),
          skuConfig.newProductTitle,
        ],
      }

      return skuSubstitutionObject
    }, {})
}
