import React, { Fragment } from "react"

export const deliveryChannelUpdates = [
  {
    courierOption: "Globo",
    message: (
      <Fragment>
        Additional shipping charges may apply for areas outside of our partner
        dispensing channel's delivery area.
      </Fragment>
    ),
  },
  {
    courierOption: "Phoenagon",
    message: (
      <Fragment>
        Additional shipping charges may apply for areas outside of our partner
        dispensing channel's delivery area.
      </Fragment>
    ),
  },
  {
    courierOption: "MedGrocer",
    message: (
      <Fragment>
        Additional shipping charges may apply for areas outside of our partner
        dispensing channel's delivery area.
      </Fragment>
    ),
  },
  {
    courierOption: "GB Distributors",
    message: (
      <Fragment>
        Additional shipping charges may apply for areas outside of our partner
        dispensing channel's delivery area.
      </Fragment>
    ),
  },
]
